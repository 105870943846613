import logo from './logo.svg';
import './App.css';
import SellersFunnelForm from './SellersFunnelForm';

function App() {
  return (
    <SellersFunnelForm/>
    
  );
}

export default App;
